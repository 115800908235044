import React from 'react';
import {
    Container,
    Box, Grid, Typography,
} from '@mui/material';
import sd_card from "../../Images/sd_card.png";
import supply_card_icon from "../../Images/supply_card_icon.png";
import supply_card_icon2 from "../../Images/supply_card_icon2.png";
import counter_bg from "../../Images/counter_bg.png";
import clock_icon from "../../Images/clock_icon.png";
import coin_icon from "../../Images/coin_icon.png";
import cal_icon from "../../Images/cal_icon.png";
import stock_plus_icon from "../../Images/stock_plus_icon.png";
import bars_icon from "../../Images/bars_icon.png";
import sd_tile_bg from "../../Images/sd_tile_bg.png";
import lpcl_icon from "../../Images/lpcl_icon.png";
import mnd_icon from "../../Images/mnd_icon.png";
const SupplyDynamics = () => {
    return (
        <>
            {/*combined thing*/}
            <Box sx={{
                display: {md:'flex', sm: 'none', xs:'none'},
                backgroundImage: `url(${sd_tile_bg})`,
                width: {md:'100%', sm: '600px', xs:'300px'},
                height: {md:'350px', sm: '250px', xs:'150px'},
                backgroundSize: '100%  100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                mx:'auto',
                marginTop: '-150px'
            }}>
                        <Box sx={{
                            margin: '8% 0 0 7%',
                        }}>
                            <Typography sx={{color:'#fff', fontSize:'60px' , fontFamily:'party' ,
                                textAlign: 'center', lineHeight: '70px'}}>
                                PRESALE <br/> BREAKDOWN
                            </Typography>
                        </Box>
                        <Box sx={{
                            margin: {lg: '5% 0 0 16%' ,md:'6% 0 0 9%'},
                        }}>
                             <Box component='img' src={lpcl_icon}
                                  sx={{
                                      width:{md:'180px',sm: '150px',xs:'100px'},
                                      height:{md:'200px',sm: '150px',xs:'100px'},
                                  }}
                             />
                        </Box>
                        <Box sx={{
                            margin: {lg: '3% 0 0 15%' ,md:'4% 0 0 9%'},
                        }}>
                             <Box component='img' src={mnd_icon}
                                  sx={{
                                      width:{md:'180px',sm: '150px',xs:'100px'},
                                      height:{md:'200px',sm: '150px',xs:'100px'},
                                  }}
                             />
                        </Box>
            </Box>
            {/*ends here*/}
            <Container maxWidth="xl" sx={{
                backgroundColor: '#fff',
                height: 'auto',
                width:'100%'
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} >
                        <Box sx={{ paddingTop: {lg:10,md:10,sm:6,xs:2} }}>
                            <Typography sx={{color:'#1390DC',fontSize: '60px', fontFamily:'party' , lineHeight: '70px'}}>
                                SUPPLY DYNAMICS
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{color:'#333',margin: '0 auto', px: 2}}>
                            <Typography>$SPACE offers an innovative and captivating game mechanic inspired by the <br/> infiniteness of space—staking (Milky Way) and burning (Black Hole).</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ marginTop: 10, textAlign: 'left' }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ marginBottom: '30px'}}>
                        <Box sx={{
                            width: {md:'500px',sm: '400px',xs:'300px'},
                            height: '103%',
                            margin:'0 auto',
                            //marginLeft:{lg: 'auto',md:'auto' , sm: '0',xs: '0'},
                            backgroundImage: `url(${sd_card})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            textAlign: 'center'
                        }}>
                    <Box component='img' src={supply_card_icon}  sx={{ width: {md:'500px',sm: '400px',xs:'300px'}, height:'250px', py:7}} />
                         <Typography sx={{color:'#fff', fontSize: {md:'35px',sm:'30px',xs:'30px'} , fontFamily:'party' }}>&nbsp; MILKY WAY(STAKING) </Typography>
                         <Typography sx={{color:'#fff',px: 3, py: 2, fontFamily:'Kanit',
                             fontSize: '14px',p:2}}>Just as our Milky Way grows bigger by absorbing smaller galaxies, $SPACE tokens can be staked to earn rewards. Stakers are rewarded with additional $SPACE tokens over time, simulating the growth of our Milky Way</Typography>
                        <Box sx={{
                            backgroundImage: `url(${counter_bg})`,
                            backgroundSize: '100%  100%',
                            borderRadius: '25px',
                            backgroundPosition: 'center',
                            width: {md:'400px',sm: '400px',xs:'250px'},
                            height:'auto',
                            margin:'20px auto',
                            textAlign: 'left',
                        }}>
                            <Typography sx={{color:'#fff', fontSize:'24px' , padding:'25px 0 0 25px', fontFamily:'party' }}> MECHANISM </Typography>
                            <Grid container alignItems="center">
                                <Grid item xs={2} display="flex" justifyContent="center">
                                    <Box
                                        sx={{
                                            width: '26px',
                                            height: '26px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            ml:'auto'
                                        }}
                                    >
                                        <Box
                                            component='img'
                                            src={cal_icon}
                                            sx={{
                                                width: '16px',
                                                height: '16px',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                 <Grid item xs={10}>
                                     <Typography sx={{color:'#fff',px: 3, py: 2, fontFamily:'Kanit',fontSize: '14px',p:2}}>
                                         Users stake $SPACE tokens for a specified period.
                                     </Typography>
                                 </Grid>
                                <Grid item xs={2} display="flex" justifyContent="center">
                                    <Box
                                        sx={{
                                            width: '26px',
                                            height: '26px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            ml:'auto'
                                        }}
                                    >
                                        <Box
                                            component='img'
                                            src={clock_icon}
                                            sx={{
                                                width: '16px',
                                                height: '16px',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                 <Grid item xs={10}>
                                     <Typography sx={{color:'#fff',px: 3, py: 2, fontFamily:'Kanit',fontSize: '14px',p:2}}>
                                         The longer the staking period, the bigger the rewards.
                                     </Typography>
                                 </Grid>
                                <Grid item xs={2} display="flex" justifyContent="center">
                                    <Box
                                        sx={{
                                            width: '26px',
                                            height: '26px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            ml:'auto'
                                        }}
                                    >
                                        <Box
                                            component='img'
                                            src={coin_icon}
                                            sx={{
                                                width: '16px',
                                                height: '16px',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                 <Grid item xs={10}>
                                     <Typography sx={{color:'#fff',px: 3, py: 2, fontFamily:'Kanit',fontSize: '14px',p:2}}>
                                         Staking rewards are distributed from the community rewards pool.
                                     </Typography>
                                 </Grid>
                             </Grid>
                        </Box>
                            <Box sx={{
                                backgroundImage: `url(${counter_bg})`,
                                backgroundSize: '100%  100%',
                                borderRadius: '25px',
                                backgroundPosition: 'center',
                                width: {md:'400px',sm: '400px',xs:'250px'},
                                height:'auto',
                                margin:'20px auto',
                                textAlign: 'left',
                            }}>
                                <Typography sx={{color:'#fff', fontSize:'24px' , padding:'25px 0 0 25px', fontFamily:'party' }}> SIMPLE MATH </Typography>
                                <Grid container alignItems="center">
                                    <Grid item xs={2} display="flex" justifyContent="center">
                                        <Box
                                            sx={{
                                                width: '26px',
                                                height: '26px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                ml:'auto'
                                            }}
                                        >
                                            <Box
                                                component='img'
                                                src={cal_icon}
                                                sx={{
                                                    width: '16px',
                                                    height: '16px',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography sx={{color:'#fff',px: 3, py: 2, fontFamily:'Kanit',fontSize: '14px',p:2}}>
                                            4.5% total allocated for a year between 3 pools: daily, weekly, and monthly
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} display="flex" justifyContent="center">
                                        <Box
                                            sx={{
                                                width: '26px',
                                                height: '26px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                ml:'auto'
                                            }}
                                        >
                                            <Box
                                                component='img'
                                                src={stock_plus_icon}
                                                sx={{
                                                    width: '16px',
                                                    height: '16px',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography sx={{color:'#fff', py: 2, fontFamily:'Kanit',fontSize: '14px',pb:1,px:2}}>
                                            {/** */}
                                            At 20% of the presale tokens evenly staked to these pools, users will get 50% APR
                                        </Typography>
                                    </Grid>
                                    <Grid xs={2}></Grid>
                                    <Grid item xs={10}>
                                        <Typography sx={{color:'#fff', fontFamily:'Kanit',pt:0,fontSize: '14px',pb:2,px:2}}>
                                            APR = % TOTAL STAKING REWARDS / % OF TOTAL SUPPLY
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    {/*Second widget*/}
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ marginBottom: '30px'}}>
                        <Box sx={{
                            width: {md:'500px',sm: '400px',xs:'300px'},
                            height: '1100px',
                            margin:'0 auto',
                            // marginLeft:{lg: 'auto',md:'auto' , sm: '0',xs: '0'},
                            backgroundImage: `url(${sd_card})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            textAlign: 'center',
                            pl: 2
                        }}>
                            <Box component='img' src={supply_card_icon2} sx={{ width: {md:'500px',sm: '400px',xs:'300px'}
                                , height:{md:'250px',sm: '200px',xs:'200px'}, py:7}} />
                            <Typography sx={{color:'#fff', fontSize:{md:'35px',sm:'30px',xs:'30px'} , fontFamily:'party' }}> BLACK HOLE(BURNING)  </Typography>
                            <Typography sx={{color:'#fff',px: 3, py: 2, fontFamily:'Kanit',fontSize: '14px',p:2}}>When galaxies are pulled by the black hole's gravitational influence, they are spaghettified and disappear. Similarly, $SPACE tokens can be burned(sphagettified) to reduce the total supply, increasing scarcity and potentially SKY-ROCKETING the value of the remaining tokens.</Typography>
                            <Box sx={{
                                backgroundImage: `url(${counter_bg})`,
                                backgroundSize: '100%  100%',
                                borderRadius: '25px',
                                backgroundPosition: 'center',
                                width: {md:'400px',sm: '400px',xs:'250px'},
                                height:'auto',
                                margin:'20px auto',
                                textAlign: 'left',
                            }}>
                                <Typography sx={{color:'#fff', fontSize:'24px' , padding:'25px 0 0 25px', fontFamily:'party' }}> MECHANICS </Typography>
                                <Grid container alignItems="center">
                                    <Grid item xs={2} display="flex" justifyContent="center">
                                        <Box
                                            sx={{
                                                width: '26px',
                                                height: '26px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                ml:'auto'
                                            }}
                                        >
                                            <Box
                                                component='img'
                                                src={bars_icon}
                                                sx={{
                                                    width: '16px',
                                                    height: '16px',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography sx={{color:'#fff',px: 3, py: 2, fontFamily:'Kanit',fontSize: '14px',p:2}}>
                                            Users can send $SPACE tokens to burn contract
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} display="flex" justifyContent="center">
                                        <Box
                                            sx={{
                                                width: '26px',
                                                height: '26px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                ml:'auto'
                                            }}
                                        >
                                            <Box
                                                component='img'
                                                src={cal_icon}
                                                sx={{
                                                    width: '16px',
                                                    height: '16px',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography sx={{color:'#fff',px: 1.5, py: 2, fontFamily:'Kanit',fontSize: '14px'}}>
                                            Every week, 25% of these tokens are burnt, 25% are sent to fund space exploration planetary defense/space weather monitoring, and 50% are orbitally decayed (raffled) among all participants, giving them back from 0.1x to 10x of tokens they burnt (with 2 times higher odds for getting less than 1x)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <br/>
                <br/>
            </Container>
        </>
    );
};

export default SupplyDynamics;
