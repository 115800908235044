import React from 'react';
import Header from "../../Layouts/Header";
import project_space_bg from '../../Images/project_space_bg.png';
import {Container, Box, Typography} from '@mui/material';
import charity from '../../Images/charity_token.png';
import counter_bg from '../../Images/counter_bg.png';
import TimeCounter from "../../Layouts/TimeCounter";
const HeroSection = () => {
    return <>
        <Container maxWidth="xl"
                   sx={{ backgroundImage: `url(${project_space_bg})`,
                       backgroundSize: '100%  100%',
                       backgroundPosition: 'center',
                       backgroundRepeat: 'no-repeat',
                       height: {md:'100%',sm:'100%', xs:'500px'},
                       width:'100%'
                   }} >
            <Box sx={{ marginBottom: '10%' }}>
                <Header />
            </Box>
            <Box>
                <Box
                    component={'img'}
                    src={charity}
                    alt=""
                    sx={{
                        width: '100%',
                        maxWidth: {md:'250px',sm:'250px',xs:'150px'},
                        marginRight: '25%',
                        height: '100%',
                        maxHeight: '100px',
                    }}
                />
            </Box>
            <Box>
                <Typography sx={{color:'#fff', fontSize: {lg:'135px',md:'100px',sm:'70px',xs:'70px'} , marginLeft: '20px', fontFamily:'party', lineHeight:  {lg:'135px',md:'100px',sm:'70px',xs:'70px'} }}>PROJECT <br/> $SPACE </Typography>
            </Box>
            <Box>
                <Typography sx={{color:'#fff', fontSize: '18px', margin: 'auto 0 0 20px', fontFamily:'Kanit'  }}>LAUNCH ON JULY 21 AT 12PM UTC </Typography>
            </Box>
            <Box sx={{
                width: {md:'400px', sm: '200px', xs:'300px'},
                backgroundImage: `url(${counter_bg})`,
                backgroundSize: '100%  100%',
                backgroundPosition: 'center',
                margin: '20px auto'
            }}>
                <Typography sx={{color:'#fff', fontSize:  {md:'50px', sm: '35px', xs:'40px'} , fontFamily:'party'  }}><TimeCounter/></Typography>
            </Box>
            <br/><br/><br/>
        </Container>
    </>
};


export default HeroSection;