import React from 'react';
import {
    Container,
    Box, Grid, Typography,
} from '@mui/material';
import sp_bg from "../../Images/sp_bg.png";
import sp_img1 from "../../Images/sp_img1.png";
import sp_img2 from "../../Images/sp_img2.png";
import sp_img3 from "../../Images/sp_img3.png";
// import sp_img3 from "../../Images/sp_img3.png";

const SPSection = () => {

    return (
        <>
            <Container maxWidth="xl" sx={{ backgroundImage: `url(${sp_bg})`,
                backgroundSize: '100%  100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#000',
                height: '100%',
                width:'100%'
            }}>
                <Box sx={{ paddingTop: 10 ,margin: '0 auto'}}>
                <Typography sx={{color:'#FFF',fontSize: {sm:'60px',xs:'40px'}, lineHeight: {sm:'60px',xs:'40px'}, fontFamily:'party' }}>
                    BECOME A $SPACE HOLDER
                </Typography>
                    </Box>
                    <Box>
                        <Typography sx={{color:'#FFF',fontSize: {sm:'55px',xs:'35px'}, fontFamily:'party'}}>
                            WIN HUGE PRICES
                        </Typography>
                    </Box>
                    <Box sx={{color:'#fff', paddingTop: 1}}>
                        <Typography sx={{ fontSize: '16px'}}>and so much more coming soon</Typography>
                    </Box>
                <Grid container spacing={3} sx={{ marginTop: 10, textAlign: 'left' }}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Box sx={{
                            backgroundImage: `url(${sp_img1})`,
                            width: {md:'350px', sm: '300px', xs:'300px'},
                            height: {md:'400px', sm: '500px', xs:'500px'},
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            mx:'auto',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <Typography sx={{color:'#FFF',fontSize: '22px', fontFamily:'party',paddingTop: {md:'230px', sm: '300px', xs:'300px'}, paddingLeft: '11%' }}>
                                SUB ORBITAL FLIGHT
                            </Typography>
                            <Typography sx={{color:'#fff',px: 5, py: 1,fontSize: '14px'}}>Experience a tour of a lifetime with the NASA sub-orbital flight on the blue origin and experience few moments of weightlessness.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Box sx={{
                            backgroundImage: `url(${sp_img2})`,
                            width: {md:'350px', sm: '300px', xs:'300px'},
                            height: {md:'400px', sm: '500px', xs:'500px'},
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            mx:'auto',
                        }}>
                            <Typography sx={{color:'#FFF',fontSize: '22px', fontFamily:'party',paddingTop: {md:'230px', sm: '300px', xs:'300px'}, paddingLeft: '11%' }}>
                                GTC VIP TOUR
                            </Typography>
                            <Typography sx={{color:'#fff',px: 5, py: 1 ,fontSize: '14px'}}>Embark on an adventure to Spain to the Gran Telescopio Canarias(GTC), the world's largest telescope and get a wonderful view of space.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Box sx={{
                            width: {md:'350px', sm: '300px', xs:'300px'},
                            height: {md:'400px', sm: '500px', xs:'500px'},
                            backgroundImage: `url(${sp_img3})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            mx:'auto',
                        }}>
                            <Typography sx={{color:'#FFF',fontSize: '22px', fontFamily:'party',paddingTop: {md:'230px', sm: '300px', xs:'300px'}, paddingLeft: '11%' }}>
                                KENNEDY SPACE CENTER TOUR
                            </Typography>
                            <Typography sx={{color:'#fff',px: 5, py: 1,fontSize: '14px'}}> Come get captivated by the views of shuttle launch pads, landing facility and a panoramic view of all the launchpads from the NASA causeway. </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <br/>
            </Container>
        </>
    );
};

export default SPSection;
