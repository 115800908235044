import React, { useEffect, useRef, useState } from "react";

function TimeCounter({startTime,endTime}) {
  const [timerDays, setTimeDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSecounds, setTimerSecounds] = useState("00");
  const [isExpired, setIsExpired] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  let interval = useRef();

  const startTimer = () => {
    let countdownDate = new Date("Jul 21, 2024, 12:00:00");
    if (countdownDate < new Date()) {
      setIsStarted(true);
      countdownDate = new Date(endTime);
    }
    if (countdownDate < new Date()) {
        setIsExpired(true);
    }
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const secound = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        setIsExpired(true);
        setIsStarted(false);
        clearInterval(interval.current);
      } else {
        setTimeDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSecounds(secound);
      }
    }, 1000);
  };
  useEffect(() => {
    startTimer();

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearInterval(interval.current);
    };
  });
  return (
    <>
      <div className="countdown-single">
        <span id="hours9" style={{ color: isStarted && !isExpired ? 'red' : '' }}>{timerDays}D :{timerHours}H :{timerMinutes}M :{timerSecounds}S</span>
      </div>
    </>
  );
}

export default TimeCounter;
