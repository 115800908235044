import React from 'react';
import {
    Container,
    Box, Typography, Grid,
} from '@mui/material';
import fbg from "../../Images/footer_bg.png";
import roundmap from "../../Images/roundmap_bg.png";

const SRSection = () => {
    return (
        <>
            <Container id='roadmap' maxWidth="xl" sx={{
                backgroundImage: `url(${fbg})`,
                backgroundPosition: 'center',
                height: '100%',
                width:'100%',
                backgroundColor: '#EEEEEF',
                paddingBottom: {lg:'15%', sm:'30%', xs:'5%'}
            }}>
                <Box sx={{ paddingTop: 10 }}>
                    <Typography sx={{color:'#1390DC',fontSize: '60px', fontFamily:'party',px: 2, lineHeight: '70px'}}>
                        $SPACE ROADMAP
                    </Typography>
                </Box>
                <br/>
                <br/>
                <Box sx={{
                    backgroundImage: {md:`url(${roundmap})`,sm:`url(${roundmap})`,xs:'' },
                    backgroundSize: '100%  100%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    textAlign:  {md:'left', sm: 'left', xs:'center'},
                    margin: '0 auto',
                    height: '1000px',
                    width: '100%',
                    maxWidth: {md:'500px',sm:'300px',xs:'80%'},
                }}>
                    <Grid container spacing={3}>
                        <Grid xs={12} sm={7} md={7}>
                            <Typography sx={{color:'#000',fontSize: '20px', fontFamily:'party',px: 2, marginTop: '10px'}}>
                                MOON
                            </Typography>
                            <Typography sx={{color:'#000',fontSize: '14px', fontFamily:'Kanit' , marginTop: '10px'}}>
                                <b>-Presale:</b> launch $SPACE token with heavy presale limits (300 Sol per wallet).<br/>
                                <b>-Dex listing:</b> listing on decentralized exchanges.<br/>
                                <b>-Marketing Campaign:</b> a huge marketing campaign that will take $SPACE to the next level.<br/>
                                <b>-CMC and Coingecko:</b> get listed on Coinmarketcap and Coingecko.<br/>
                            </Typography>
                        </Grid>
                        <Grid xs={0} sm={5} md={5} ></Grid>
                        <Grid xs={0} sm={5} md={5}  ></Grid>
                        <Grid xs={12} sm={7} md={7} sx={{ paddingLeft: {md:'18%', sm: '18%', xs: '0'}, mt: {md:'25%', sm: '25%'} }}>
                            <Typography sx={{color:'#55CC55',fontSize: '20px', fontFamily:'party',px: {lg:2,md:2}, my:2, marginTop: '10px'}}>
                                EARTH
                            </Typography>
                            <Typography sx={{color:'#000',fontSize: '14px', fontFamily:'Kanit' , marginTop: '10px'}}>
                                <b>-Cex listings:</b> $SPACE will be listed on centralized exchanges.<br/>
                                <b>-Scarcity:</b> implement Milky Way (Staking) and Black Hole (Burning) mechanics.<br/>
                                <b>-Airdrop campaigns:</b> P2E games meant to reward the community.<br/>
                                <b>-NFT Collection:</b> Release exclusive $SPACE NFT collection.<br/>
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={7} md={7} >
                            {/*<br/>*/}
                            {/*<br/>*/}
                            <Typography sx={{color:'#DFC600',fontSize: '20px', fontFamily:'party',px: 2, marginTop: '10px'}}>
                                SUN
                            </Typography>
                            <Typography sx={{color:'#000',fontSize: '14px', fontFamily:'Kanit' , marginTop: '10px'}}>
                                <b>-Tier1 Cex listings: </b> secure listings on tier-1 centralized exchanges. <br/>
                                <b>-Partnerships: </b> form huge partnership with well known global entities and celebrities <br/>
                                <b>-Charity fund: </b> funding global initiatives, campaign and research relating to space and human civilization. <br/>

                            </Typography>
                        </Grid>
                        <Grid xs={0} sm={5} md={5}></Grid>
                        <Grid xs={0} sm={5} md={5}></Grid>
                        <Grid xs={12} sm={7} md={7} >
                            <Typography sx={{color:'#A54AFB',fontSize: '20px', fontFamily:'party',px: 2,my:2, mt: {md:'40%', sm: '40%'}}}>
                                STAR
                            </Typography>
                            <Typography sx={{color:'#000',fontSize: '14px', fontFamily:'Kanit' , marginTop: '10px'}}>
                               <b>Global adoption:</b> push for a global mass adoption of $SPACE<br/>
                                <b>-Influencers:</b> engage the world's biggest influencers to promote $SPACE.<br/>
                                <b>-Rewards:</b> offer massive rewards for $SPACE holders.<br/>
                                <b>-Stellar Infinity:</b> introduce our P2E game where players earn by engaging virtually in $SPACE related activities.<br/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
};

export default SRSection;
