 import './App.css';
import PageAWrapper from "./Pages/PageA/PageAWrapper";

function App() {
  return (
    <div className="App">
      <PageAWrapper />
    </div>
  );
}

export default App;
