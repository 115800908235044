import * as React from "react";
import { Container, Stack, Box, Typography, Link } from "@mui/material";
import button from '../Images/PresaleButton.png';
import MenuIcon from '@mui/icons-material/Menu';
import MobileDrawer from "./MobileDrawer";
const Header = () => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    return (
        <Container maxWidth="xl">
            <br/>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    width: { md: '70%', sm: '100%', xs: '100%' }
                }}
                backgroundColor="#EFEFEF"
                mx="auto"
                gap={1}
                px={1}
                py={1}
            >
                <Box>
                    <Typography sx={{ color: '#09063E', fontSize: { md: '35px', sm: '25px', xs: '18px' }, marginLeft: '20px', fontFamily: 'party' }}>$SPACE</Typography>
                </Box>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={{lg: 10, md: 6, sm: 4, xs: 2}}
                    sx={{
                        display: {md: 'flex', sm: 'flex', xs: 'none'},
                        mx:'auto'
                    }}
                >
                    <Link href="#features" underline="none" sx={{ color: '#09063E', fontFamily:'Kanit', fontWeight:600 ,fontSize:  { md: '16px', sm: '14px', xs: '12px' } }}>
                        FEATURES
                    </Link>
                    <Link href="#utility" underline="none" sx={{ color: '#09063E', fontFamily:'Kanit', fontWeight:600 ,fontSize:  { md: '16px', sm: '14px', xs: '12px' } }}>
                        UTILITY
                    </Link>
                    <Link href="#roadmap" underline="none" sx={{ color: '#09063E', fontFamily:'Kanit', fontWeight:600 ,fontSize:  { md: '16px', sm: '14px', xs: '12px' } }}>
                        ROADMAP
                    </Link>
                    <Link href="#faqs" underline="none" sx={{ color: '#09063E', fontFamily:'Kanit', fontWeight:600 ,fontSize: { md: '16px', sm: '14px', xs: '12px' } }}>
                        FAQ
                    </Link>
                </Stack>
                    <Box
                        component='img'
                        src={button}
                        alt=""
                        sx={{
                            display: {md:'block', sm:'block', xs: 'none'} ,
                            width: {lg:'110px', md: '80px', sm: '80px', xs: '60PX' },
                            ml:'auto'
                         }}
                    ></Box>
                <MenuIcon sx={{display: {md: 'none', sm: 'none', xs: 'flex'}, ml:'auto'}} onClick={toggleDrawer(true)} />
                <MobileDrawer open={open} toggleDrawer={toggleDrawer} />
            </Stack>
        </Container>
    );
}

export default Header;
