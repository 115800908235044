import React from 'react';
import {
    Container,
    Box, Grid, Typography
} from '@mui/material';
import pr_bg from "../../Images/pr_bg.png";
import pr_mini_bg from "../../Images/pr_mini_bg.png";
import therock1 from "../../Images/therock1.png";
import therock2 from "../../Images/therock2.png";
import therock3 from "../../Images/therock3.png";
import therock4 from "../../Images/therock4.png";
import therock5 from "../../Images/therock5.png";
import copy_btn from "../../Images/copy_btn.png";
import counter_bg from "../../Images/counter_bg.png";
import TimeCounter from "../../Layouts/TimeCounter";

const PrivateRoundSection = () => {
    return (
        <>
            <Container maxWidth="xl" sx={{ backgroundImage: `url(${pr_bg})`,
                backgroundSize: '100%  100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#000',
                height: '103%',
                width:'100%'
            }}>
                <br/>
                <Box sx={{ paddingTop: 4 ,margin: '20px auto', width:'80%', height:'auto', backgroundImage: `url(${pr_mini_bg})`,
                    backgroundSize: '100%  100%',
                    p: 2,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat' }}>
                    <Typography sx={{color:'#fff', fontSize:'32px' , fontFamily:'Kanit', fontWeight: '600' }}>PRESALE DETAILS </Typography>

                    <Box sx={{
                        backgroundImage: `url(${counter_bg})`,
                        backgroundSize: '100%  100%',
                        backgroundPosition: 'center',
                        width: {md:'600px',sm: '500px',xs:'100%'},
                        height:'100%',
                        maxHeight: '90px',
                        margin:'20px auto',
                    }}>
                        <Grid container>
                            <Grid xs={6}>
                                <Typography sx={{color:'#fff', fontSize: {md:'20px', sm: '18px', xs:'14px'} ,
                                    fontFamily:'Kanit', fontWeight: '400', float: 'left', padding: '20px' }}>
                                    Address Will Be Shared Soon
                                </Typography>
                            </Grid>
                            <Grid xs={6}>
                                <Box
                                    component={'img'}
                                    sx={{
                                        float: 'right', padding: '20px', width:  {md:'150px', sm: '150px', xs:'60%'} , my:{md:'auto', sm: 'auto', xs:'15px'}, cursor:'pointer'
                                    }}
                                    src={copy_btn}
                                    alt=""></Box>
                             </Grid>
                        </Grid>
                    </Box>

                    <Typography sx={{color:'#fff', fontSize:'24px' , fontFamily:'Kanit', fontWeight: '600' }}>PRESALE BONUSES ON EACH ROUND!</Typography>
                    <Typography sx={{color:'#D8D6D6', fontSize:'16px' , fontFamily:'Kanit', fontWeight: '400', lineHeight: '20px' }}>
                        Unallocated round tokens -> WILL BE BURNED!<br/>
                        Maximum Investment: 300 $SOL<br/>
                        Minimum Investment: 0.1 $SOL</Typography>

                    <Grid container sx={{ marginTop: '20px' }}>
                        <Grid xs={0} sm={0} md={0} lg={1}></Grid>
                        <Grid xs={6} sm={6} md={4} lg={2} sx={{ marginBottom: '30px'}}>
                            <Typography sx={{color:'#fff', fontSize:'24px' , fontFamily:'Kanit', fontWeight: '600', lineHeight: '10px' }}>+50%</Typography>
                            <Box sx={{
                                backgroundImage: `url(${counter_bg})`,
                                backgroundSize: '100%  100%',
                                backgroundPosition: 'center',
                                width: '130px',
                                height:  '60px',
                                margin:'20px auto',
                                borderRadius: '11px'
                            }}>
                                <Typography sx={{color:'#fff', fontSize:'22px' , fontFamily:'Kanit', fontWeight: '600', p:1.5 }}>Round 1</Typography>

                            </Box>
                            <Typography sx={{color:'#fff', fontSize:'18px' , fontFamily:'Kanit', fontWeight: '500', lineHeight: '10px' }}>3500 $SOL</Typography>

                        </Grid>
                        <Grid  xs={6} sm={6} md={4} lg={2} sx={{ marginBottom: '30px'}}>
                            <Typography sx={{color:'#fff', fontSize:'24px' , fontFamily:'Kanit', fontWeight: '600', lineHeight: '10px' }}>+35%</Typography>
                            <Box sx={{
                                backgroundImage: `url(${counter_bg})`,
                                backgroundSize: '100%  100%',
                                backgroundPosition: 'center',
                                width: '130px',
                                height:  '60px',
                                margin:'20px auto',
                                borderRadius: '11px'
                            }}>
                                <Typography sx={{color:'#fff', fontSize:'22px' , fontFamily:'Kanit', fontWeight: '600', p:1.5 }}>Round 2</Typography>

                            </Box>
                            <Typography sx={{color:'#fff', fontSize:'18px' , fontFamily:'Kanit', fontWeight: '500', lineHeight: '10px' }}>5000 $SOL</Typography>

                        </Grid>
                        <Grid  xs={6} sm={6} md={4} lg={2} sx={{ marginBottom: '30px'}}>
                            <Typography sx={{color:'#fff', fontSize:'24px' , fontFamily:'Kanit', fontWeight: '600', lineHeight: '10px' }}>+25%</Typography>
                            <Box sx={{
                                backgroundImage: `url(${counter_bg})`,
                                backgroundSize: '100%  100%',
                                backgroundPosition: 'center',
                                width: '130px',
                                height:  '60px',
                                margin:'20px auto',
                                borderRadius: '11px'
                            }}>
                                <Typography sx={{color:'#fff', fontSize:'22px' , fontFamily:'Kanit', fontWeight: '600', p:1.5 }}>Round 3</Typography>

                            </Box>
                            <Typography sx={{color:'#fff', fontSize:'18px' , fontFamily:'Kanit', fontWeight: '500', lineHeight: '10px' }}>7000 $SOL</Typography>

                        </Grid>
                        <Grid  xs={6} sm={6} md={4} lg={2} sx={{ marginBottom: '30px'}}>
                            <Typography sx={{color:'#fff', fontSize:'24px' , fontFamily:'Kanit', fontWeight: '600', lineHeight: '10px' }}>+15%</Typography>
                            <Box sx={{
                                backgroundImage: `url(${counter_bg})`,
                                backgroundSize: '100%  100%',
                                backgroundPosition: 'center',
                                width: '130px',
                                height:  '60px',
                                margin:'20px auto',
                                borderRadius: '11px'
                            }}>
                                <Typography sx={{color:'#fff', fontSize:'22px' , fontFamily:'Kanit', fontWeight: '600', p:1.5 }}>Round 4</Typography>

                            </Box>
                            <Typography sx={{color:'#fff', fontSize:'18px' , fontFamily:'Kanit', fontWeight: '500', lineHeight: '10px' }}>9000 $SOL</Typography>

                        </Grid>
                        <Grid xs={6} sm={6} md={4} lg={2} sx={{ marginBottom: '30px'}}>
                            <Typography sx={{color:'#fff', fontSize:'24px' , fontFamily:'Kanit', fontWeight: '600', lineHeight: '10px' }}>+10%</Typography>
                            <Box sx={{
                                backgroundImage: `url(${counter_bg})`,
                                backgroundSize: '100%  100%',
                                backgroundPosition: 'center',
                                width: '130px',
                                height:  '60px',
                                margin:'20px auto',
                                borderRadius: '11px'
                            }}>
                                <Typography sx={{color:'#fff', fontSize:'22px' , fontFamily:'Kanit', fontWeight: '600', p:1.5 }}>Round 5</Typography>

                            </Box>
                            <Typography sx={{color:'#fff', fontSize:'18px' , fontFamily:'Kanit', fontWeight: '500', lineHeight: '10px' }}>11,055 $SOL</Typography>
                        </Grid>
                        <Grid xs={0} sm={0} md={0} lg={1}></Grid>
                    </Grid>
                    <br/>
                    <Typography sx={{color:'#fff', fontSize:'22px' , fontFamily:'Kanit', fontWeight: '600'  }}>MAXIMUM OF 36 HOURS EACH STAGE</Typography>
                    <Typography sx={{color:'#fff', fontSize:  {md:'50px', sm: '35px', xs:'35px'} , fontFamily:'party'  }}>
                        <TimeCounter/>
                    </Typography>
                    {/*<Box sx={{ width: '80%', mx: 'auto' }}>*/}
                    {/*    <Grid container>*/}
                    {/*        <Grid xs={6}>*/}
                    {/*            <Typography sx={{color:'#fff', fontSize:'16px' , fontFamily:'Kanit', fontWeight: '400', float:'left'  }}>0 $SOL</Typography>*/}
                    {/*        </Grid>*/}
                    {/*        <Grid xs={6}>*/}
                    {/*            <Typography sx={{color:'#fff', fontSize:'16px' , fontFamily:'Kanit', fontWeight: '400', float: 'right'  }}>38,888 $SOL</Typography>*/}
                    {/*        </Grid>*/}
                    {/*    </Grid>*/}
                    {/*</Box>*/}
                    <br/>
                    <br/>
            </Box>
            <Box sx={{ paddingTop: 10 ,margin: '0 auto'}} id="features">
                <Typography sx={{color:'#fff', fontSize:'65px' , marginLeft: '20px', fontFamily:'party', lineHeight:'60px' }}>KEY FEATURES: <br/> $SPACE COIN
                </Typography>
            </Box>
                {/*Rocks*/}
        <Grid container spacing={3} sx={{ marginTop: 10 }}>
            <Grid item  xs={12} sm={4} md={2} lg={2} >
                <Box sx={{
                        width: '100%',
                        maxWidth: '140px',
                        height:'100%',
                        maxHeight: '130px',
                        margin:'0 auto',
                    }}>
                    <img
                        src={therock1}
                        alt=""/>
                </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2} >
                <Box sx={{
                        width: '100%',
                        maxWidth: '140px',
                        height:'100%',
                        maxHeight: '130px',
                        paddingTop: {md:10, sm:6, xs:0},

                    margin:'0 auto',
                    }}>
                    <img
                        src={therock2}
                        alt=""/>
                </Box>
            </Grid>
            <Grid item  xs={12} sm={4} md={3} lg={3}  >
                <Box sx={{
                        width: '100%',
                        margin: '0 auto',
                        maxWidth: '140px',
                        height:'100%',
                        maxHeight: '130px',
                    }}>
                    <img
                        src={therock3}
                        alt=""/>
                </Box>
            </Grid>
            <Grid item  xs={12} sm={4} md={2} lg={2}  >
                <Box sx={{
                        width: '100%',
                        maxWidth: '180px',
                        height:'100%',
                        maxHeight: '180px',
                        paddingTop: {md:10, sm:6, xs:0},
                    margin:'0 auto',
                    }}>
                    <img
                        src={therock4}
                        alt=""/>
                </Box>
            </Grid>
            <Grid item  xs={12} sm={4} md={3} lg={3}  >
                <Box sx={{
                        width: '100%',
                        maxWidth: '140px',
                        height:'100%',
                        maxHeight: '130px',
                        margin:'0 auto',
                    }}>
                    <img
                        src={therock5}
                        alt=""/>
                </Box>
            </Grid>
        </Grid>
                <br/>
                <br/>
            </Container>
        </>
    );
};

export default PrivateRoundSection;
