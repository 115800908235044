import React from 'react';
import {
    Container,
    Box, Grid, Typography, useMediaQuery
} from '@mui/material';
import solarSystem from "../../Images/solarSystem.png";
import astro_sp_token from "../../Images/astro_sp_token.png";
import astro_mob_bg from "../../Images/astro_mob_bg.png";

const AstroSection = () => {
    const matches = useMediaQuery('(max-width:600px)');
    return (
        <>
            <Container maxWidth="xl" sx={{
                backgroundImage: {md:`url(${solarSystem})`,sm:`url(${solarSystem})`,xs:`url(${astro_mob_bg})` },
                backgroundSize: '100%  100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: {sm:'100%',xs:'70%'},
                width:'100%',
                mt:3
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4} sx={{
                        display: {md: 'block', sm: 'block', xs: 'none'},
                    }}>
                             <img
                                src={astro_sp_token}
                                alt=""
                                style={{
                                    width:  '215px',
                                    float: 'right',
                                }}
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} >
                        <Box sx={{ maxWidth:'400px', marginRight: {lg: 'auto', md:'auto' , sm: '0',xs: '0'}}}>
                            <Typography sx={{color:'#fff',fontSize: {md:'60px',sm:'40px',xs:'30px'} , fontFamily:'party' ,
                                lineHeight: {md:'60px',sm:'40px',xs:'30px'}}}>
                                ASTR0NOMICS <br/> 99,999,999,999
                            </Typography>
                        </Box>
                        </Grid>
                    </Grid>
                {
                    matches ? '':
                        <>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        </>
                }
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </Container>
        </>
    );
};

export default AstroSection;
