import React from 'react';
import {
    Container,
    Box, Grid, Typography, useMediaQuery
} from '@mui/material';
import rocket from "../../Images/rocket.png";
import sc2_card_bg from "../../Images/sc2_card_bg.png";

const KFSection = () => {
    const matches = useMediaQuery('(max-width:600px)');
    return (
        <>
            <Container id='utility' maxWidth="xl" sx={{
                backgroundColor: '#fff',
                height: '100%',
                width:'100%'
            }}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} >
            <Box sx={{ paddingTop: 10 , maxWidth:'400px',
                marginLeft: {lg: 'auto', md:'auto' , sm: '0',xs: '0'}}}>
                <Typography sx={{color:'#1390DC',fontSize: {sm:'60px',xs:'40px'},
                    fontFamily:'party' , lineHeight: {sm:'70px',xs:'40px'}}}>
                GET $Space<br/>
                    In 4 Steps
                </Typography>

            </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
            <Box sx={{ width: '150px', height: '150px',marginTop:  {lg: 10,md:10 , sm: '0',xs: '0'},
                marginLeft:  {lg: 0,md:0, sm: 'auto',xs: 'auto'}, marginRight:  {lg: 0,md:0, sm: 'auto',xs: 'auto'} }}>
                        <img
                            src={rocket}
                            alt=""
                            style={{
                                width: '100%',
                                maxWidth: '350px',
                                height:'100%',
                                maxHeight: '200px',
                            }}
                        />
                    </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{color:'#333',margin: '0 auto', px: 2, textAlign: {sm:'center' , xs:'left'}}}>
                    <Typography>Welcome to Project Space, a revolutionary meme coin initiative emphasizing purity,{matches ? '':<br/>}
                        life and transparency. $SPACE stands out for its commitment to a safer and more {matches ? '':<br/>}
                        ethical approach to cryptocurrency.</Typography>
                </Box>
            </Grid>
        </Grid>
                <Grid container spacing={3} sx={{ marginTop: 10, textAlign: 'left' }}>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Box sx={{
                            width: '290px',
                            height: '370px',
                            margin:'0 auto',
                            marginLeft:{lg: 'auto',md:'auto'},
                            backgroundImage: `url(${sc2_card_bg})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <Typography sx={{color:'#1390DC',fontSize: '48px', padding:'30px 0 0 20px ', fontFamily:'party'}}>01</Typography>

                            <Typography sx={{color:'#fff', fontSize:'22px' , marginLeft: '20px', fontFamily:'party' }}>PREPARE A WALLET </Typography>
                            <Typography sx={{color:'#fff',px: 3, py: 2,fontSize: '14px'}}>The potential $SPACE holder should have a Solana-based wallet to operate with NFT and cryptocurrencies. Phantom Wallet works perfectly fine.</Typography>
                            <Typography sx={{color:'#E0E0E0',px:3 ,fontSize: '14px'}}>Presale goes until the Presale Cap Limit of 35,555 $SOL. Be careful and don’t send any Solana to the presale wallet after the presale is over.</Typography>
                        </Box>
                    </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Box sx={{
                            width: '290px',
                            height: '370px',
                            margin:'0 auto',
                            marginLeft:{lg: 'auto',md:'auto'},
                            backgroundImage: `url(${sc2_card_bg})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <Typography sx={{color:'#1390DC',fontSize: '48px', padding:'30px 0 0 20px ', fontFamily:'party'}}>02</Typography>
                            <Typography sx={{color:'#fff', fontSize:'22px' , marginLeft: '20px', fontFamily:'party' }}>BUY SOLANA </Typography>

                            <Typography sx={{color:'#fff',px: 3, py: 2,fontSize: '13px'}}>To participate, you need to have $SOL in your wallet. You can buy that on any CEX exchange like Binance, Bybit, Crypto.com, Coinbase, or another, and send it to your wallet.</Typography>
                        </Box>
                    </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Box sx={{
                            width: '290px',
                            margin:'0 auto',
                            height: '370px',
                            backgroundImage: `url(${sc2_card_bg})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            marginLeft:{lg: 'auto',md:'auto' },
                        }}>
                            <Typography sx={{color:'#1390DC',fontSize: '48px', padding:'30px 0 0 20px ', fontFamily:'party'}}>03</Typography>
                            <Typography sx={{color:'#fff', fontSize:'22px' , marginLeft: '20px', fontFamily:'party' }}>BUY $SPACE </Typography>

                            <Typography sx={{color:'#fff',px: 3, py: 2,fontSize: '13px'}}>After that, you should send up to 300 $SOL from THE PHANTOM WALLET to the specified Presale address that will appear on the website spacecoin.wtf after the announcement of the Presale start.</Typography>
                        </Box>
                    </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Box sx={{
                            width: '290px',
                            height: '370px',
                            margin:'0 auto',
                            backgroundImage: `url(${sc2_card_bg})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            marginLeft:{lg: 'auto',md:'auto' },
                        }}>
                            <Typography sx={{color:'#1390DC',fontSize: '48px', padding:'30px 0 0 20px ', fontFamily:'party'}}>04</Typography>
                            <Typography sx={{color:'#fff', fontSize:'22px' , marginLeft: '20px', fontFamily:'party' }}>ADD SPACE TO YOUR <br/> WALLET </Typography>
                            <Typography sx={{color:'#fff',px: 3, py: 2,fontSize: '13px'}}>Listing happens within 24 hours after the presale has ended. TGE starts the moment the token is listed. Have a question? Read WIKI</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <br/>
            </Container>
        </>
    );
};

export default KFSection;
