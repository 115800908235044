import React from 'react';
import HeroSection from "./HeroSection";
import KFSection from "./KeyFeaturesSection";
import SPSection from "./SPSection";
import RTHSSection from "./RTHSSection";
import PrivateRoundSection from "./PrivateRoundSection";
import Footer from "../../Layouts/Footer";
import QuestionsSection from "./QuestionsSection";
import SFP from "./SFP";
import SITSection from "./SITSection";
import SRSection from "./SRSection";
import AstroSection from "./AstroSection";
import SupplyDynamics from "./SupplyDynamics";
const PageAWrapper = () => {
        return <>
                <HeroSection />
                <KFSection />
                <PrivateRoundSection />
                <RTHSSection/>
                <SPSection />
                <SRSection />
                <AstroSection />
                <SupplyDynamics />
                <SITSection />
                <SFP/>
                <QuestionsSection/>
                <Footer/>
               </>
};


export default PageAWrapper;