import React from 'react';
import {
    Container,
    Box, Grid, Typography, useMediaQuery
} from '@mui/material';
import sp_bg from "../../Images/sp_bg.png";
import sit_astro from "../../Images/sit_astro.png";
import SIT_bg from "../../Images/SIT_bg.png";

const SITSection = () => {
    const matches = useMediaQuery('(max-width:600px)');
    return (
        <>
            <Container maxWidth="xl" sx={{ backgroundImage: `url(${sp_bg})`,
                backgroundSize: '100%  100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#000',
                height: '100%',
                width:'100%'
            }}>
                <Box sx={{ paddingTop: 10 ,margin: '0 auto'}}>
                <Typography sx={{color:'#FFF',fontSize: {md:'60px',sm:'50px',xs:'40px'}, fontFamily:'party', lineHeight: {lg:'60px',md:'50px',sm:'40px',xs:'40px'}}}>
                     $SPACE IS AN <br/> IMPACT TOKEN
                </Typography>
                    </Box>
                    <Box sx={{color:'#fff', paddingTop: 1}}>
                        <Typography sx={{ fontSize: '14px'}}>Understanding the impact of $SPACE is important for everyone, which is why we developed the first <br/> meme tokenomics dedicated to supporting space exploration. By harnessing the power of the <br/> meme community, we aim to collectively make Earth a safer place and other planets habitable</Typography>
                    </Box>
                <Grid container spacing={3} sx={{ marginTop: 10, textAlign: 'left'  }}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Box sx={{
                            width:  {md:'350px', sm: '300px', xs:'290px'},
                            height:  {md:'370px', sm: '430px', xs:'450px'},
                            margin:'0 auto',
                            backgroundImage: `url(${SIT_bg})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <Typography sx={{color:'#FFF',fontSize: '60px', fontFamily:'party',marginTop: '30px', paddingLeft: '11%' }}>
                                01
                            </Typography>
                            <br/>
                            <Typography sx={{color:'#FFF',fontSize: '24px', fontFamily:'party',marginTop: '10px', paddingLeft: '11%' }}>
                                DEDICATED CHARITY WALLET
                            </Typography>
                            <Typography sx={{color:'#fff',px: 5, py: 1,fontSize: '14px'}}>We will have a dedicated and cosmic, <br/> publicly known wallet address to use for <br/> charity donations and planetary protection <br/> missions aimed to make our planet safer.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <br/>
                        <br/>
                        <Box sx={{
                            width:  {md:'350px', sm: '300px', xs:'290px'},
                            height:  {md:'500px', sm: '600px', xs:'650px'},
                            margin:'0 auto',
                            backgroundImage: `url(${SIT_bg})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <br/>
                            <Typography sx={{color:'#FFF',fontSize: '60px', fontFamily:'party', paddingLeft: '11%' }}>
                                02
                            </Typography>
                            <br/>
                            <Typography sx={{color:'#FFF',fontSize: '24px', fontFamily:'party',marginTop: '10px', paddingLeft: '11%' }}>
                                SUSTAINABLE OPPORTUNITIES <br/> AND INTERESTING PLANS
                            </Typography>
                            <Typography sx={{color:'#fff',px: 5, py: 1 ,fontSize: '14px'}}>Unlike many meme tokens that lack universal value, $SPACE stands out; as it is a meme token encompassing not just Earth, but the Universe. This token exists to ensure fair asset redistribution among every holder while also supporting planetary defense causes. With $SPACE, you can contribute to keeping our planet safe, without any personal loss.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        { matches ? '' : <> <br/> <br/> <br/> <br/> </> }
                        <Box sx={{
                            width:  {md:'350px', sm: '300px', xs:'290px'},
                            height:  {md:'500px', sm: '600px', xs:'550px'},
                            margin:'0 auto',
                            backgroundImage: `url(${SIT_bg})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <br/>
                            <Typography sx={{color:'#FFF',fontSize: '60px', fontFamily:'party', paddingLeft: '11%' }}>
                                03
                            </Typography>
                            <br/>
                            <Typography sx={{color:'#FFF',fontSize: '24px', fontFamily:'party',marginTop: '10px', paddingLeft: '11%' }}>
                                COSMIC GROWTH AND STRONG  <br/> MARKETING
                            </Typography>
                            <Typography sx={{color:'#fff',px: 5, py: 1 ,fontSize: '14px'}}>As it should be, we are constantly improving the approach and financial balancing models to unlock the astral potential of $SPACE with outstanding Marketing and Performance.</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ textAlign: 'left'}}>
                    <Box component={'img'} src={sit_astro} sx={{ height:{md:'300px',sm:'200px', xs:'200px'}, width: {md:'500px',sm:'350px', xs:'300px'} }} ></Box>
                </Box>
            </Container>
        </>
    );
};

export default SITSection;