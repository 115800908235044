import React from 'react';
import {
    Container,
    Box, Typography, Grid, Button,
} from '@mui/material';
import fbg from "../../Images/footer_bg.png";
const SFP = () => {
    return (
        <>
            <Container maxWidth="xl" sx={{
                backgroundImage: `url(${fbg})`,
                backgroundSize: '100%  100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                width:'100%',
                backgroundColor: '#fff',
            }}>
                <Box sx={{ paddingTop: 10 }}>
                    <Typography sx={{color:'#1390DC',fontSize: '60px', fontFamily:'party',px: 2, lineHeight: '70px'}}>
                        $SPACE FUTURE PRODUCTS
                    </Typography>
                </Box>
                            <br/>
                            <Grid container spacing={3}   >
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Box
                                        sx={{
                                            backgroundColor: '#131348',
                                            padding: '20px',
                                            borderRadius: '18px',
                                            textAlign: 'center',
                                            color: 'white',
                                            margin:'0 auto',
                                            width: {md:'350px',sm:'300px',xs:'80%'},
                                            height: '200px',
                                        }}
                                    >
                                        <h2>DONATE $SPACE NFT <br/> SERIES</h2>
                                        <Button
                                            sx={{
                                                backgroundColor: '#ff9900',
                                                color: 'white',
                                                marginTop: '10px',
                                                borderRadius: '55px',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                px:3,
                                                py:1,
                                                '&:hover': {
                                                    backgroundColor: '#ff9900',
                                                    opacity: 0.9,
                                                },
                                            }}
                                        >
                                            Coming Soon
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Box
                                        sx={{
                                            backgroundColor: '#131348',
                                            margin:'0 auto',
                                            padding: '20px',
                                            borderRadius: '18px',
                                            textAlign: 'center',
                                            color: 'white',
                                            width: {md:'350px',sm:'300px',xs:'80%'},
                                            height: '200px',
                                        }}
                                    >
                                        <h2>MILKY WAY AND BLACK  <br/>HOLE $SPACE</h2>
                                        <Button
                                            sx={{
                                                backgroundColor: '#ff9900',
                                                color: 'white',
                                                marginTop: '10px',
                                                borderRadius: '55px',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                px:3,
                                                py:1,
                                                '&:hover': {
                                                    backgroundColor: '#ff9900',
                                                    opacity: 0.9,
                                                },
                                            }}
                                        >
                                            Coming Soon
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                    <Box
                                        sx={{
                                            backgroundColor: '#131348',
                                            padding: '20px',
                                            margin:'0 auto',
                                            borderRadius: '18px',
                                            textAlign: 'center',
                                            color: 'white',
                                            width: {md:'350px',sm:'300px',xs:'80%'},
                                            height: '200px',
                                        }}
                                    >
                                        <h2>STELLAR INFINITY <br/> GAME</h2>
                                        <Button
                                            sx={{
                                                backgroundColor: '#ff9900',
                                                color: 'white',
                                                marginTop: '10px',
                                                borderRadius: '55px',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                px:3,
                                                py:1,
                                                '&:hover': {
                                                    backgroundColor: '#ff9900',
                                                    opacity: 0.9,
                                                },
                                            }}
                                        >
                                            Coming Soon
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>

                <br/>
                <br/>
                <br/>
                <br/>
            </Container>
        </>
    );
};

export default SFP;
