import React from 'react';
import {
    Container,
    Box, Typography, Grid,
} from '@mui/material';
import pr_bg from "../../Images/pr_bg.png";
import pr_mini_bg from "../../Images/pr_mini_bg.png";
import rock_insta from "../../Images/rock_insta.png";
import rock_x from "../../Images/rock_x.png";
import rock_tele from "../../Images/rock_tele.png";
import wiki_btn from "../../Images/wiki_btn.png";
const QuestionsSection = () => {
    return (
        <>
            <Container id="faqs" maxWidth="xl" sx={{ backgroundImage: `url(${pr_bg})`,
                backgroundSize: '100%  100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#000',
                height: '100%',
                width:'100%'
            }}>
            <Box sx={{ paddingTop: 10 ,margin: '0 auto'}}>
                <Typography sx={{color:'#fff', fontSize:'65px' , fontFamily:'party', lineHeight: '65px' }}>HAVE ANY <br/> QUESTIONS? </Typography>
                <Typography sx={{color:'#fff', fontSize:'16px' , fontFamily:'kanit' }}> More detailed information can also be found in our Wiki </Typography>
                <Box sx={{
                     width:'150px', height: '40px',
                    backgroundImage: `url(${wiki_btn})`,
                    backgroundSize: '100%  100%',
                    backgroundPosition: 'center',
                    margin: '20px auto',
                }}>
                    <Typography sx={{color:'#131348', fontSize: '16px' ,p:1, fontWeight: '600', cursor:'pointer', ':hover': {
                            opacity: 0.7,
                        },  }}>$SPACE WIKI</Typography>
                </Box>
            </Box>
                <br/>
                <Box sx={{ paddingTop: 3 ,margin: '0 auto', width:'80%', height:'auto', backgroundImage: `url(${pr_mini_bg})`,
                    backgroundSize: '100%  100%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat' }}>
                    <Typography sx={{color:'#fff', fontSize:'50px' , fontFamily:'party', fontWeight: '600'
                    }}>SAFE SPACE </Typography>
                <Grid container spacing={3} sx={{ marginTop: 2 , width:'80%', margin:'0 auto'}}>
                    <Grid item  xs={12} sm={12} md={4} lg={4} >
                        <a href={'https://www.instagram.com/spacememecoin?igsh=MTdteTFpMTBycmVlcQ=='} target="_blank" rel="noreferrer">
                        <Box sx={{
                            width: '140px',
                            height: '130px',
                            cursor: 'pointer',
                            margin:'0 auto',
                            ':hover': {
                                opacity: 0.7,
                            },
                        }}>
                            <img
                                src={rock_insta}
                                style={{
                                    width: '140px',
                                    height:'130px',
                                }}
                                alt=""/>
                        </Box>
                        </a>
                    </Grid>
                    <Grid item  xs={12} sm={12} md={4} lg={4} >
                        <a href={'http://X.com/spacememecoin'} target="_blank" rel="noreferrer">
                        <Box
                            sx={{
                            width: '140px',
                            height:  '130px',
                            marginTop: '30px',
                            margin:'0 auto',
                            cursor: 'pointer',
                            ':hover': {
                                opacity: 0.7,
                            },
                        }}>
                            <img
                                src={rock_x}
                                style={{
                                    width: '140px',
                                    height:'130px',
                                }}
                                alt=""/>
                        </Box>
                        </a>
                    </Grid>
                    <Grid item   xs={12} sm={12} md={4} lg={4}  >
                        <a href={'https://t.me/spacesolmeme'} target="_blank" rel="noreferrer">
                        <Box sx={{
                            width: '140px',
                            margin:'0 auto',
                            height:'130px',
                            cursor: 'pointer',
                            ':hover': {
                                opacity: 0.7,
                            },
                        }}>
                            <img
                                src={rock_tele}
                                style={{
                                    width: '140px',
                                    height:'130px',
                                }}
                                alt=""/>
                        </Box>
                        </a>
                    </Grid>
                </Grid>
                    <br/>
                </Box>
                <br/>
                <br/>
            </Container>
        </>
    );
};

export default QuestionsSection;
