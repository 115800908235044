import React from 'react';
import {
    Container,
    Box,  Typography,
} from '@mui/material';
import fbg from "../Images/footer_bg.png";

const Footer = () => {
    return (
        <>
            <Container maxWidth="xl" sx={{
                backgroundImage: `url(${fbg})`,
                backgroundSize: '100%  100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                width:'100%',
                backgroundColor: '#fff',
            }}>
                        <Box sx={{ paddingTop: 10 }}>
                            <Typography sx={{color:'#1390DC',fontSize: '60px', fontFamily:'party',px: 2, lineHeight: '70px'}}>
                                $Space
                            </Typography>
                        </Box>
                            <br/>
                            <Typography sx={{color:'#2E2E2E',fontSize: '14px', fontFamily:'party',px: 2, lineHeight: '10px'}}>
                                Where Stars Are Born and Dreams Take Flight
                            </Typography>
                            {/*<br/>*/}
                            {/*<Typography sx={{color: '#2E2E2E',fontSize: '14px', fontFamily:'party',px: 2, fontWeight: '400'}}>*/}
                            {/*    <b>RISK WARNING:</b> Trading cryptocurrencies carries a high level of risk and may not be suitable for all investors. Cryptocurrency trading is <br/>*/}
                            {/*    highly speculative, and you could lose some or all of your invested capital. Therefore, do not speculate with capital that you cannot afford to <br/>*/}
                            {/*    lose, The content on this site should not be construed as investment advice. All investment carries risks. Your capital is at risk.<br/>*/}
                            {/*    It is recommended that you seek advice from a qualified financial advisor before making any investment decisions.*/}
                            {/*</Typography>*/}
                            <br/>
                            <Typography sx={{color:'#2E2E2E',fontSize: '14px', fontFamily:'party',px: 2, fontWeight: '400'}}>
                                <b>Restricted Countries:</b> Residents of Afghanistan, Benin, Bhutan, China, Crimea region, Cuba, Iran, Iraq, Syria, USA, Vatican City, or any person <br/>
                                in any jurisdiction where such distribution or use would be contrary to local law or regulation should not participate in the presale. The <br/>
                                information on this site is not intended for use by individuals in jurisdictions where the offering or sale of the tokens is not permitted by law.
                            </Typography>
                <br/>
                <br/>
                <br/>
                <br/>
            </Container>
        </>
    );
};

export default Footer;
