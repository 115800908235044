import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import {Link, SwipeableDrawer, Typography} from "@mui/material";

export default function MobileDrawer({open, toggleDrawer}) {
    const  theList = [{
        name:'FEATURES',
        tag: '#features'
    },
        {
            name:'UTILITY',
            tag: '#utility'
        },
        {
            name:'ROADMAP',
            tag: '#roadmap'
        },
        {
            name: 'FAQ',
            tag: '#faqs'
        } ]
    const list = () => (
        <Box
            sx={{ width:  250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {theList.map((data, index) => (
                    <ListItem key={index} disablePadding>
                        <Link href={data.tag} underline="none" sx={{ color: '#09063E', fontFamily:'Kanit', fontWeight:600
                            ,fontSize:  { md: '16px', sm: '14px', xs: '12px' } }}>
                        <ListItemButton sx={{
                            width: '100%',
                            mx:'auto'
                        }}>
                                {data.name}
                        </ListItemButton>
                    </Link>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    return (
        <div>
        <React.Fragment>
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <Box sx={{
                    textAlign: 'center'
                }}>
                    <Typography sx={{ color: '#09063E', fontSize: '35px', fontFamily: 'party' }}>$SPACE</Typography>
                </Box>
                {list()}
            </SwipeableDrawer>
        </React.Fragment>
</div>
);
}
