import React from 'react';
import {
    Container,
    Box, Grid, Typography,
} from '@mui/material';
import card_bg from "../../Images/card_bg.png";
import kf_icon1 from "../../Images/kf_icon1.png";
import kf_icon2 from "../../Images/kf_icon2.png";
import kf_icon3 from "../../Images/kf_icon3.png";
import kf_icon4 from "../../Images/kf_icon4.png";
import kf_icon5 from "../../Images/kf_icon5.png";

const RTHSSection = () => {

    return (
        <>
            <Container maxWidth="xl" sx={{
                backgroundColor: '#fff',
                height: '100%',
                width:'100%'
            }}>
                <Box sx={{ paddingTop: 10 , height: '70px',margin: '0 auto'}}>
                    <Typography sx={{color:'#1390DC',fontSize: '60px', fontFamily:'party',px: 2, lineHeight: '70px'}}>
                      REASON TO HAVE $SPACE
                    </Typography>
                </Box>
                <Grid container spacing={3} sx={{ marginTop: 10, textAlign: 'center' }}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box sx={{
                            margin:'0 auto',
                            width: '100%',
                            maxWidth: '350px',
                            height: '100%',
                            maxHeight: '350px',
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${card_bg})`,
                        }}>
                            <img
                                src={kf_icon1}
                                style={{ marginTop: '60px' }}
                                alt=""/>
                            <br/>
                            <Typography sx={{color:'#FFF',fontSize: '24px', fontFamily:'party', marginTop: '10px'}}>
                                CHARITY FIRST
                            </Typography>
                            <Typography sx={{color:'#fff',px: 3, py: 2,fontSize: '14px', fontFamily:'Kanit'}}>  Global charity and research initiatives that will interest millions of people. $SPACE sets to achieve a positive impact on the real world with the help of charity.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box sx={{
                            margin:'0 auto',
                            width: '100%',
                            maxWidth: '350px',
                            height: '100%',
                            maxHeight: '350px',
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${card_bg})`,
                        }}>
                            <img
                                src={kf_icon2}
                                style={{ marginTop: '60px' }}
                                alt=""/>
                            <br/>
                            <Typography sx={{color:'#FFF',fontSize: '24px', fontFamily:'party', marginTop: '10px'}}>
                                $SPACE FOR EVERYONE
                            </Typography>
                            <Typography sx={{color:'#fff',px: 3, py: 2,fontSize: '14px', fontFamily:'Kanit'}}>Presale is limited to 200 Solana per wallet, making sure everyone has a fair share of $space.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box sx={{
                            margin:'0 auto',
                            width: '100%',
                            maxWidth: '350px',
                            height: '100%',
                            maxHeight: '350px',
                            backgroundImage: `url(${card_bg})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <img
                                src={kf_icon3}
                                style={{ marginTop: '60px' }}
                                alt=""/>
                            <br/>
                            <Typography sx={{color:'#FFF',fontSize: '24px', fontFamily:'party', marginTop: '10px'}}>
                                ZERO PERPETUALS
                            </Typography>
                            <Typography sx={{color:'#fff',px: 3, py: 2,fontSize: '14px', fontFamily:'Kanit'}}> To reduce volatility and prevent market manipulation, perpetual contracts will be avoided at the beginning stage of our journey.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box sx={{
                            float: {lg:'right',md:'right'},
                            width: '100%',
                            maxWidth: '350px',
                            height: '100%',

                            margin:'0 auto',
                            maxHeight: '350px',
                            backgroundImage: `url(${card_bg})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <img
                                src={kf_icon4}
                                style={{ marginTop: '60px' }}
                                alt=""/>
                            <br/>
                            <Typography sx={{color:'#FFF',fontSize: '24px', fontFamily:'party', marginTop: '10px'}}>
                                PRE-AGREED TOP LISTINGS
                            </Typography>
                            <Typography sx={{color:'#fff',px: 3, py: 2,fontSize: '14px', fontFamily:'Kanit'}}>Several pre-agreed listings has been concluded with top tier exchanges with liquidity and features.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box sx={{
                            float: {lg:'left',md:'left'},
                            width: '100%',
                            maxWidth: '350px',
                            margin:'0 auto',
                            height: '100%',
                            maxHeight: '350px',
                            backgroundImage: `url(${card_bg})`,
                            backgroundSize: '100%  100%',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <img
                                src={kf_icon5}
                                style={{ marginTop: '60px' }}
                                alt=""/>
                            <br/>
                            <Typography sx={{color:'#FFF',fontSize: '24px', fontFamily:'party', marginTop: '10px'}}>
                                VESTED PARTNERS & KOLS
                            </Typography>
                            <Typography sx={{color:'#fff',px: 3, py: 2,fontSize: '14px', fontFamily:'Kanit'}}> All our top partners & KOLS are limited with publicly visible tokens vesting to ensure long term involvement and support.</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <br/>
            </Container>
        </>
    );
};

export default RTHSSection;
